global.$ = global.jQuery = $;

const _client = require('./_ext/client.js');
const modalHelper = require('./_ext/_modalhelper.js').default;

const CHECK_EP = '/check_session';
const REFRESH_EP = '/refresh_session';

const POLL_WAIT = 15;
const TIMEOUT_WARNING_TICK = 60;

const SessionWatcher = function () {
    let _data = {};
    let _warningShown = false;
    let _checkTick = null;

    let _object = {
        getCheckTick: function () {
            return _checkTick;
        },
        resetTick: function () {
            _checkTick = null;

            return _object;
        },
        zeroTick: function () {
            _checkTick = 0;

            return _object;
        },
        doTick: function () {
            return _checkTick++;
        },
        getWarningShown: function () {
            return _warningShown;
        },
        showWarningModal: function () {
            _warningShown = true;
            let modal = modalHelper.showModal({
                title: 'Idle Timeout',
                message: 'Due to a period of inactivity, you are about to be logged out.<br><strong>Would you like to continue your session?</strong>',
                buttons: [
                    {
                        text: 'Continue',
                        isPrimary: true,
                        buttonClass: 'btn-primary',
                        pullLeft: true,
                        event: function (element, modal) {
                            _object.refreshSession();
                            _warningShown = false;
                            modal.remove();
                        }
                    },
                    {
                        text: 'Logout (<span class="logout-timer">' + TIMEOUT_WARNING_TICK + '</span>)',
                        isDefault: true,
                        isOutlined: true,
                        buttonClass: 'btn-logout-timeout',
                        event: function (element, modal) {
                            window.location = '/logout'
                        }
                    }
                ]
            });

            // let startCounter = _object.getData().data.time_remaining - 1;
            // let counter = startCounter;
            const tickModal = function () {
                if ($("body").is(".login-page")) {
                    return;
                }

                if ($(modal).is(":visible")) {
                    var seconds = _object.getTimeoutTick();

                    if (seconds > TIMEOUT_WARNING_TICK) {
                        _warningShown = false;
                        modal.remove();
                    }

                    if (seconds <= 0) {
                        $(modal).find('.btn-logout-timeout').html("Logging out...")
                        return;
                    }

                    $('.logout-timer', modal).html(seconds);
                    setTimeout(tickModal, 250);
                }
            };

            tickModal();
        },
        getData: function () {
            return _data
        },
        getTimeoutTick: function () {
            if (!_object.getData().data.expires_at) {
                return 0;
            }
            
            var expires_at = new Date(_object.getData().data.expires_at + 'Z');
            var now = new Date();

            var differenceInMilliseconds = expires_at - now;
            var diff = Math.floor(differenceInMilliseconds / 1000)

            return diff > 0 ? diff : 0;
        },
        checkSession: function (force = false) {
            return new Promise((resolve, reject) => {
                if (!force && _object.getCheckTick() !== null && _object.getCheckTick() < POLL_WAIT) {
                    resolve(_object);
                    return;
                }

                _object.zeroTick();
                _client.handleRequest(CHECK_EP, 'GET', null, null, (data) => {
                    _data = data ?? null;
                    resolve(_object);
                }, reject);
            });
        },
        refreshSession: function () {
            return new Promise((resolve, reject) => {
                _client.handleRequest(REFRESH_EP, 'POST', null, null, (data) => {
                    _data = data ?? null;
                    resolve(_object);
                }, reject);
            });
        }
    }

    return _object;
}

const afterCheck = function (_object) {
    let nextCheckTick = POLL_WAIT;
    let timeRemaining = _object.getTimeoutTick();

    if ($("body").is(".login-page")) {
        if (timeRemaining > TIMEOUT_WARNING_TICK) {
            window.location = window.location;
        }
        return;
    } else {
        if (!timeRemaining) {
            if ($("div.error-page").length) {
                // window.location = "/"
                return;
            } else {
                window.location = window.location;
                // window.sessionWatcher.checkSession().then(function()
                // {
                //     if (_object.getTimeoutTick() > 0) {
                //         return;
                //     }
                //     window.location = window.location;
                // });
            }
            return;
        }

        if (timeRemaining <= TIMEOUT_WARNING_TICK) {
            
            if (timeRemaining < 1) {
                window.sessionWatcher.checkSession(true).then(function()
                {
                    if (_object.getTimeoutTick() > TIMEOUT_WARNING_TICK) {
                        return;
                    }
                    if (!window.sessionWatcher.getWarningShown()) {
                        window.sessionWatcher.showWarningModal();
                    }
                });
            } else {
                if (!window.sessionWatcher.getWarningShown()) {
                    window.sessionWatcher.showWarningModal();
                }
            }
        }

        nextCheckTick = Math.min(nextCheckTick, timeRemaining);
    }

    if (nextCheckTick < 1) {
        nextCheckTick = 1;
    }

    setTimeout(function () {
        _object.doTick();
        nextCheck();
    }, 1000);
};

const nextCheck = function () {
    window.sessionWatcher.checkSession().then(afterCheck);
};

$(function () {
    window.sessionWatcher = SessionWatcher();

    if (!$("body").is(".login-page")) {
        nextCheck();
    } else {
        $(window).on("focus", function () {
            window.sessionWatcher.resetTick();
            nextCheck();
        });
    }
}); 
