global.$ = global.jQuery = $;

// require('bootstrap/js/src/modal.js');

const PRESET_BUTTONS = {
    close: {
        text: 'Close',
        isClose: true,
        isDefault: true,
        pullLeft: true,
        isOutlined: true
    }
}

const _body = $('body');

$(window).on('resize', () => {
    $('body > .modal').css({ overflowY: _body.height() > $(window).height() ? 'scroll' : '', paddingRight: '' });
});

const modalFactory = function (message, title, buttons, modalType, showModal) {
    if (typeof (modalType) == 'undefined')
        modalType = 'default';

    let html =
        "<div class=\"modal modal-b\" tabindex=\"-1\" role=\"dialog\">\n" +
        "  <div class=\"modal-dialog\" role=\"document\">\n" +
        "    <div class=\"modal-content\">\n" +
        "      <div class=\"modal-header\">\n" +
        "        <h5 class=\"modal-title\"></h5>\n" +
        "        <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n" +
        "          <span aria-hidden=\"true\">&times;</span>\n" +
        "        </button>\n" +
        "      </div>\n" +
        "      <div class=\"modal-body\">\n" +
        "      </div>\n" +
        "      <div class=\"modal-footer\">\n" +
        "      </div>\n" +
        "    </div>\n" +
        "  </div>\n" +
        "</div>";


    let modalDialog = $(html)
        .addClass("modal-" + modalType)
        .css({ overflowY: _body.height() > $(window).height() ? 'scroll' : '' });

    modalDialog.find(".modal-body").html(message);
    modalDialog.find(".modal-title").html(title);

    $(modalDialog).on('hidden.bs.modal', function () {
        $(this).remove();
        _body.css({ overflowY: '' });
    });

    $(modalDialog).on('shown.bs.modal', function (e) {
        $(this).css({ paddingRight: '' });
    });

    var modalFooter = modalDialog.find(".modal-footer");

    $.each(buttons, function (index, element) {
        if ('object' !== typeof (element) || !element.hasOwnProperty('text'))
            return;

        let btn = $("<button type=\"button\" class=\"btn\"></button>");

        if (element.hasOwnProperty('isDefault') && true === element.isDefault) {
            btn.addClass('btn-default');
        }

        if (element.hasOwnProperty('buttonClass') && element.buttonClass) {
            btn.addClass(Array.isArray(element.buttonClass) ? element.buttonClass.join(" ") : element.buttonClass);
        }

        if (element.hasOwnProperty('pullLeft') && true === element.pullLeft) {
            btn.addClass('pull-left');
        }

        if (element.hasOwnProperty('isClose') && true === element.isClose) {
            btn.attr('data-dismiss', 'modal');
            $(btn).on("click", function(e) {
                $(this).closest(".modal").remove();    
            });
        }

        if (element.hasOwnProperty('isPrimary') && true === element.isPrimary) {
            btn.addClass('btn-primary');
        }

        if (element.hasOwnProperty('isOutlined') && true === element.isOutlined) {
            btn.addClass('btn-outline');
        }

        if (element.hasOwnProperty('text') && '' !== element.text) {
            btn.html(element.text);
        }

        if (element.hasOwnProperty('event') && typeof (element.event) == 'function') {
            btn.on('click', function () {
                element.event(element, modalDialog);
            });
        }

        modalFooter.append(btn);
    });

    if ('undefined' === typeof (showModal) || showModal) {
        // modalDialog.modal();
        modalDialog.appendTo("body");
    }

    return modalDialog;
};

/*
   EXAMPLE:

    let deletePageConfirmation = {
        title: 'Are you sure you wish to delete this page?',
        message: 'Continue?',
        buttons: [
            {
                text: 'Yes, delete this page',
                isPrimary: true,
                buttonClass: 'btn-danger',
                event: function()
                {
                    // callback on click
                }
            },
            {
                text: 'Close',
                isClose: true,
                isDefault: true,
                pullLeft: true,
                isOutlined: true
            }
        ]
    };
 */

const funcs = {
    showModal: function (modelConfig) {
        return modalFactory(modelConfig.message, modelConfig.title, modelConfig.buttons, modelConfig.type || 'default');
    },
    showWarningModal: function (modelConfig) {
        return modalFactory(modelConfig.message, modelConfig.title, modelConfig.buttons, 'warning');
    },
    showDangerModal: function (modelConfig) {
        return modalFactory(modelConfig.message, modelConfig.title, modelConfig.buttons, 'danger');
    },
    getPresetButtons: function() {
        return PRESET_BUTTONS;
    }
};

$(function() {
    $(document).on('click', '.modal-b .close', function()
    {
        $(this).closest('.modal').remove();
    });
})

export default funcs;