global.$ = global.jQuery = $;

const _alertHelper = require('./_alerthelper.js');

const spawnNewFL = function(_config)
{
    _config.variant = (_config.variant ?? '') + ' fl-loading';
    return $.featherlight('<div class="embed-wrapper"><div class="text-center">'+window.featherlightLoading+'</div></div>', _config);
}

const handleFeatherlight = function(_url, _config)
{
    spawnNewFL(_config);
    let _container = window.flGetInstance();
    handleRequest(_url, 'GET', null, _container, () => {}, () => {
        if (_container.is('.fl-loading')) {
            _container.find('.featherlight-close').click();
        } else {
            _container.find('.cancel-btn').not('.save-btn').prop('disabled', null).removeClass('disabled');
        }
    }, true);
};

const handleComplete = function(data, after = () => {}, container = null, allowNotify = false) {
    let embedComment = '<!-- embed -->';

    if (data.startsWith(embedComment)) {
        let html = data.substr(embedComment.length).trim();
        if (container) {
            if (window.destroyDatepickers) {
                window.destroyDatepickers(container);
            }
            let newHtml = $(html);
            newHtml.addClass('featherlight-inner');
            container.find('.embed-wrapper').replaceWith(newHtml);
            if (container.find('.embed-lg').length > 0) {
                container.addClass('fl-large');
            } else {
                container.removeClass('fl-large');
            }
        } else {
            if (allowNotify) {
                $.featherlight($(html), window.buildConfig());
            }
        }

        window.initFeatherlight();

        after(data, container);
    } else {
        let objData = null;
        try {
            objData = JSON.parse(data);
        } catch (e) {
        }

        if (objData) {
            let redirectTo = objData['redirect_to'] ?? null;
            if (redirectTo) {
                if ((redirectTo.includes('?embed') || redirectTo.includes('&embed'))) {
                    if (container) {
                        container.addClass('fl-loading');
                    }

                    handleRequest(redirectTo, 'GET', null, container, function(data, container) {
                        after(objData ?? data, container, redirectTo);
                        _alertHelper.showData(objData);

                        if (container) {
                            container.removeClass('fl-loading');
                        }
                    });
                } else {
                    let currentNav = window.flPreventNavigation ?? false;
                    window.flPreventNavigation = false;
                    window.location = redirectTo;
                    setTimeout(function() {
                        after(objData ?? data, container);
                        window.flPreventNavigation = currentNav;
                    }, 1000);
                }
                return;
            } else {
                after(objData ?? data, container);
            }
        } else {
            if (container) {
                container.find('.featherlight-close').click();
            }
        }

        if (objData) {
            _alertHelper.showData(objData);
        }
    }
};

const handleRequest = function(url, method = 'GET', data, container = null, after = () => {}, error = () => {}, allowNotify = false, contentType = false, processData = false) {
    return $.ajax({
        type: method,
        url: url,
        dataType: "html",
        data: data,
        contentType: contentType,
        processData: processData,
        success: function(data, textStatus, xhr) {
            if (typeof this.statusCode[xhr.status] != 'undefined') {
                return false;
            }
            handleComplete(data, after, container, allowNotify);
        },
        error: function(xhr, status) {
            if (typeof this.statusCode[xhr.status] != 'undefined') {
                return false;
            }
            console.log('ajax.error.unhandled');
        },
        statusCode: {
            202: function(data) {
                // save & close
                handleComplete(data.responseText ?? data ?? '', () => {
                    window.flGetInstance().find('.featherlight-close').click();
                }, container, allowNotify);
            },
            404: function(data) {
                handleComplete(data.responseText ?? '', error, container, allowNotify);
            },
            400: function(data) {
                handleComplete(data.responseText ?? '', error, container, allowNotify);
            },
            500: function(data) {
                handleComplete(data.responseText ?? '', error, container, allowNotify);
            }
        }
    });
};

const removeURLParameter = function(url, parameter) {
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

const addURLParameters = function(url, values) {
    var urlparts = url.split('?');
    var pars = (urlparts[1] ?? '').split(/[&;]/g);

    for(let parameter in values) {
        pars.push(encodeURIComponent(parameter) + '=' + encodeURIComponent(values[parameter]));
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
}

module.exports = {
    handleRequest: handleRequest,
    handleComplete: handleComplete,
    handleFeatherlight: handleFeatherlight,
    spawnNewFL: spawnNewFL,
    removeURLParameter: removeURLParameter,
    addURLParameters: addURLParameters
}