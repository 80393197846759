
const _body = $('body');

const makeMsg = function(messageType, message)
{
    return $('<div class="col-sm-12"><div class="alert-wrapper"><div class="alert alert-' + messageType + ' alert-dismissible"><button type="button" title="Dismiss" class="close" data-dismiss="alert" aria-hidden="true">×</button>' + message + '</div></div></div>');
};

const getFeatherlight = function()
{
    return $('.featherlight').not('.fl-closing').last();
};

const getWrapper = function()
{
    let _fl = getFeatherlight();
    if (_fl.length > 0) {
        return _fl.find('.embed-wrapper');
    } else {
        let wrap = $('body .body-wrap .site-area');
        if (wrap.length > 0) {
            return wrap;
        }

        return $('body .wrapper > .content-wrapper .content');
    }
};

const getAlertContainer = function(spawn = true)
{
    let wrapper = getWrapper();
    let alertRow = $('.alert-row', wrapper);
    if ((!alertRow.is(".row") && !alertRow.is(".d-flex")) && spawn) {
        let siteHeading = wrapper.find('.site-heading');

        alertRow = $('<div class="row alert-row show-few">')
        if (siteHeading.length > 0) {
            alertRow.insertAfter(siteHeading);
        } else {
            alertRow.prependTo(wrapper);
        }

        $(alertRow).on('click', '.close', _alertCloseClick);
    } else {
        return alertRow.last();
    }

    return alertRow;
};

const getControls = function()
{
    let wrapper = getWrapper();
    let controlsRow = $('.notif-control', wrapper);
    if (!controlsRow.is(".row")) {
        let html =
            "<div class=\"alert-wrapper\">" +
                "<div class=\"alert alert-info cursor-pointer\">" +
                    "<span class=\"alert-text\"></span>" +
                    "<button type=\"button\" title='Dismiss All' class=\"clear-btn\" aria-hidden=\"true\"><i class=\"far fa-times-circle\"></i></button>" +
                "</span>" +
            "</div>";

        let siteHeading = wrapper.find('.site-heading');

        if (siteHeading.length > 0) {
            controlsRow = $('<div class="row notif-control">').insertAfter(siteHeading);
        } else {
            controlsRow = $('<div class="row notif-control">').prependTo(wrapper);
        }
        controlsRow.append(html);

        controlsRow.find('.clear-btn').on('click', function (e) {
            wrapper.find('.alert .close').trigger('click');

            e.preventDefault();
            e.stopPropagation();
        });

        controlsRow.find('.alert').on('click', function () {
           getAlertContainer().toggleClass('show-few');
           afterMsg();
        });
    }

    return controlsRow;
};

const showMsg = function(messageType, message, container = null, after = true)
{
    let alertRow = container ?? getAlertContainer();
    let msg = makeMsg(messageType, message);
    let alert = msg.find('.alert-wrapper').css('font-weight', 600);
    alert.appendTo(alertRow);

    setTimeout(function() {
        alert.css('font-weight', '');
    }, 2500)

    if (after) {
        afterMsg();
    }
};

const showData = function(objData)
{
    let alertRow = getAlertContainer();

    if (objData.all && objData.all.length) {
        for (let x in objData.all) {
            showMsg(objData.all[x].messageType, objData.all[x].message, alertRow, false);
        }
    }
    if (objData.message) {
        showMsg(objData.messageType, objData.message, alertRow, false);
    }
    afterMsg();
};

const afterMsg = () =>
{
    let _ac = getAlertContainer();
    let showFew = _ac.hasClass('show-few');
    let hiddenCount = _ac.children('div:hidden').length;

    if (!showFew) {
        _ac.addClass('show-few');
        hiddenCount = _ac.children('div:hidden').length;
        _ac.removeClass('show-few');
    }

    if (hiddenCount > 0) {

        let prefix = 'Show';
        if (!showFew) {
            prefix = 'Hide'
        }

        getControls().show().find('.alert .alert-text').html(prefix + ' ' + hiddenCount + ' more notification' + (hiddenCount !== 1 ? 's' : ''));
    } else {
        getControls().remove();
    }
};

const _alertCloseClick = function(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).closest('.alert-wrapper').fadeOut('slow', function(){ $(this).remove(); afterMsg(); });
};


$(document).ready(function()
{
    let _ac = getAlertContainer(false);
    if (_ac) {
        $(_ac).on('click', '.close', _alertCloseClick);
    }

    afterMsg();
});

module.exports = {
    showMsg: showMsg,
    showData: showData
};